<template>
  <v-dialog
    v-model="show"
    light
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
    :width="$vuetify.breakpoint.mobile ? '' : '80%'"
    >
    <v-card flat>

      <v-card-title class="azul2 turquesa--text">
        AVISO LEGAL - DISCLAIMER <v-spacer />
        <v-btn :ripple="false" text plain @click="closeDialog">
          <img src="assets/ic-close-celest.svg" width="16px"/>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container class="pt-12 text-left">
          <v-row>
            <v-col>
              <span class="chivo">
                <p>
                  <b>TOKENFIT S.A.S.</b>
                  (en adelante TOKENFIT) es una sociedad con sede social en Avda. Corrientes Nº832 piso 2 of. 23 de la ciudad de Rosario, Provincia de Santa Fe, Argentina, correo electrónico contacto@tokenfit.io.
                </p>
                <p>
                  El contenido de este documento resulta aplicable a quien use, acceda y/o navegue en la página web tokenfit.io. (en adelante el sitio web y/o tokenfit.io)
                </p>
                <p>
                  Quien realice cualesquiera de las acciones enunciadas y/u otras acciones similares y/o derivadas de las anteriores, será considerado Usuario y adherente pleno y sin reservas de las condiciones de uso que surjan de la tokenfit.io y que sean publicadas en cada momento en se acceda a este sitio web.
                </p>
                <p>
                  El presente documento regula las responsabilidades que pudieren derivarse y/o generarse por el empleo de los contenidos de tokenfit.io, en sentido amplio.
                </p>
                <p>
                  Es por ello que el Usuario debe LEER ATENTAMENTE este Aviso Legal así como los Términos y Condiciones generales, los particulares, en su caso y la Política de Privacidad.
                </p>
                <p>
                  TOKENFIT se reserva el derecho de modificar los Términos y Condiciones generales y/o particulares del sitio web, la Política de Privacidad y el Aviso Legal,   cuando lo considere oportuno. El uso del sitio web significará su aceptación de cualquier ajuste a tales términos, por parte del Usuario. Todo cambio en nuestros Términos y Condiciones, Política de Privacidad y/o Asivo Legal, será anunciado en nuestra página principal. Si hay algún cambio en nuestra página respecto a la manera en que usamos la información de identificación personal de nuestros Usuarios, enviaremos una notificación por correo electrónico o correo postal a aquellos que sean afectados por el cambio, siempre que el mismo haya sido provisto por el Usuario. Se recomienda al Usuario volver a leer esta declaración de manera regular.
                </p>
                <p>
                  <b>1.- Usuario:</b>
                </p>
                <p>
                  Se denominará usuario a toda persona que acceda, utilice y/o navegue en tokenfit.io, ya sea que se trate de una participación activa - gratuita u onerosa- o de simple espectador.
                </p>
                <p>
                  El Usuario debe hacer un uso correcto de tokenfi.io, en cumplimiento de la normativa vigente, la buena fe, el orden público, los usos y costumbres y el presente documento. En caso de que el Usuario debiera proceder a su registro en el sitio web, para la utilización de un servicio y/o contratación, deberá aportar información veraz y lícita.
                </p>
                <p>
                  En el supuesto de incumplimiento de la obligación mencionada será responsable por los daños y perjuicios que ocasionare o pudiere ocasionarle a TOKENFIT y/o terceros.
                </p>
                <p>
                  El Usuario debe leer atentamente los Términos y Condiciones de la contratación, antes de utilizar los servicios y/o adquirir tokens en el Marketplace de TOKENFIT.  La mera utilización del sitio web y/o la contratación a través tokenfit.io será considerada aceptación tácita de las Términos y Condiciones.
                </p>
                <p>
                  El acceso a tokenfit.io es de carácter libre y gratuito, sin perjuicio de ello, cuando sean requeridos datos personales al usuario al momento de acceder a determinado servicio y/o sector del sitio web, o para participar de una operación de compra o adquisición y/o contratación a través de tokenfit.io, se entenderá que el Usuario acepta los dispuesto en Política de Privacidad con relación al tratamiento de sus datos personales. El Usuario debe LEER ATENTAMENTE la Política de Privacidad.
                </p>
                <p>
                  Está prohibida la contratación por parte de menores de edad, salvo que cuenten previamente con la debida autorización de sus representantes legales. Caso contrario, se considerará a los padres, tutores y/o representantes legales como responsables de los actos del menor a su cargo.
                </p>
                <p>
                  <b>2.- Propiedad Intelectual. Responsabilidad</b>
                </p>
                <p>
                  TOKENFIT es titular de los gráficos, registros y de todo el contenido que figura en el sitio web tokenfit.io, de manera que queda prohibido a los Usuarios la publicación, copia, reproducción y/o utilización de cualquier forma sin la debida autorización por parte de TOKENFIT.
                </p>
                <p>
                  El proyecto TOKENFIT consiste en una prueba de concepto, de manera que es de carácter experimental, fue desarrollado sobre la base del conocimiento de la tecnología blockchain y el derecho de fondo y de forma.
                </p>
                <p>
                  Tokenfit.io no pretende realizar un análisis exhaustivo ni brindar asesoramiento legal, técnico ni regulatorio. Si bien toda la información que contiene, así como los puntos de vista vertidos, son brindados de manera diligente y de buena fe, TOKENFIT  no asume obligación ni responsabilidad alguna por cualquier error y/o inexactitud que pueda contener. Asimismo tampoco se obliga a brindar mayor información y/o a ampliar datos o fuentes a los destinatarios.
                </p>
                <p>
                  Al tratarse de una prueba de concepto sin antecedente conocido de un proyecto similar en la República Argentina, al menos por quienes han desarrollado el presente, TOKENFIT no garantiza que los resultados reales sean consistentes con el proyecto planteado. Los organismos administrativos que resulten competentes en el desarrollo de la prueba de concepto no han examinado la información incluida en este documento. Es por ello que cualquier obstáculo u observación que pudiera formularse en cualquiera de las etapas de su ejecución obedece a que el mismo se encuentra en etapa experimental y no es responsabilidad de los desarrolladores.
                </p>
                <p>
                  Tokenfit.io y su contenido no implican oferta ni invitación a contratar, el sitio web no obliga a TOKENFIT con los destinatarios.  En el caso de la subasta, el acuerdo se realizará entre el titular registral del inmueble y el adquirente en subasta, se regirá exclusivamente por documentos separados y por los Términos y Condiciones que figuran en el sitio web tokenfit.io.
                </p>
                <p>
                  La tecnología blockchain al igual que los avances de la tecnología en general pueden presentar riesgos e incertidumbres, la volatilidad de algunas criptomonedas y otras condiciones de esta industria hacen aconsejable que toda persona que esté dispuesta a invertir y/o participar de experiencias en el mundo blockchain, deba informarse previamente de los riesgos y costes asociados.
                </p>
                <p>
                  Es por ello que TOKENFIT aconseja al Usuario leer atentamente los Términos y Condiciones de la contratación, antes de utilizar los servicios y/o adquirir tokens en el Marketplace de TOKENFIT.  La mera utilización del sitio web y/o la contratación a través tokenfit.io será considerada aceptación tácita de las Términos y Condiciones.
                </p>
                <p>
                  TOKENFIT no se hace responsable por variaciones en el precio y/o fluctuaciones en el valor de criptoactivos que sean utilizados como seña y/o como pago total de la oferta, fees u otros. La DApp que desarrollará TOKENFIT puede variar con relación a lo descrito en el litepaper o bien puede fracasar por varias razones, entre ellas la falta de financiación, la falta de éxito comercial y factores externos, entre otros, sin que la presente pueda ser considerada como una descripción taxativa.
                </p>
                <p>
                  <b>3.- Notificaciones</b>
                </p>
                <p>
                  Todas las notificaciones y/o comunicaciones entre TOKENFIT y los Usuarios deberá realizarse por escrito,  a través de correo electrónico a la dirección contacto@tokenfit.io, con firma electrónica y/o superior. En caso de no contar con mecanismo de validación de firma, deberá dirigirse por medio postal a Avda. Corrientes Nº 832 piso 2 of. 23 de la ciudad de Rosario (CP 2000), provincia de Santa Fe, Argentina.
                </p>
                <p>
                  <b>4.- Nulidad parcial</b>
                </p>
                <p>
                  La aceptación tácita o expresa por parte del Usuario de los Términos y Condiciones del presente sitio web, así como de todo el contenido de este último, debe interpretarse como integral. La nulidad de alguna previsión no implicará la nulidad del resto y deberá considerarse parcial, sin afectación del total de las consideraciones, derechos y obligaciones contenidos en el sitio web.
                </p>
                <p>
                  <b>5.- Renuncia</b>
                </p>
                <p>
                  El no ejercicio o ejecución por parte de TOKENFIT de cualquier derecho o disposición emergente de las disposiciones contenidas en el sitio web no constituirá una renuncia al mismo, salvo reconocimiento y acuerdo por escrito por su parte.
                </p>
                <p>
                  <b>6.- Jurisdicción</b>
                </p>
                <p>
                  El Usuario y TOKENFIT se sometarán a la jurisdicción que resulte competente y a la legislación aplicable, en caso de que así surja de disposiciones imperativas. Para aquellos casos en que la normativa prevea la posibilidad de prórroga de jurisdicción, TOKENFIT y el Usuario se someten a los Tribunales Ordinarios de la ciudad de Rosario, provincia de Santa Fe, Argentina, renunciando a cualquier otro fuero que pudiera corresponderles, incluido el federal.
                </p>
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import EventBus from '@/utils/eventbus'
  export default {
    name: 'AvisoDialog',
    data() {
      return {
        show: false,
      }
    },
    created() {
      EventBus.$on('show-aviso-dialog', this.showDialog);
      EventBus.$on('hide-aviso-dialog', this.closeDialog);
    },
    methods: {
      showDialog() {
        this.show = true
        EventBus.$emit('show-dialog')
      },
      closeDialog() {
        this.show = false
        EventBus.$emit('hide-dialog')
      }
    }
  }
</script>

<style>

</style>